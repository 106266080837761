
import { Vue, Component } from "vue-property-decorator";
import AuthService from "@/services/auth_service";
import { Roles } from "@/models/user/roles";
import { GenericError } from "@/services/error_service";

@Component
export default class Register extends Vue {
  protected Roles = Roles;
  protected email = "";
  protected password = "";
  protected optionalData = { repEmail: "", role: Roles.Unverified, company: "" }; //2023-05-10_TreyThompson: The 'company' field is no longer considered 'optional'. Is currently required on the MachForm.
  protected first = "";
  protected last = "";
  protected error = "";
  protected success = "";
  protected show1 = false;
  protected automated = false;
  protected passwordRules = [
    (value: string) => !!value || "Please enter a password.",
    (value: string) => !!value || "Required",
    (value: string) => (value && /\d/.test(value)) || "At least one digit",
    (value: string) =>
      (value && /[^A-Za-z0-9]/.test(value)) || "At least one special character",
    (value: string) => (value && value.length > 7) || "minimum 8 characters",
  ];
  protected companyRules = [
    (value: string) => !!value || "Please enter a company name."
  ];

  protected authService = new AuthService();

  created() {
    if (this.$route.name === "RegisterAutomated") {
      this.automated = true;
      if (!this.$route.query.key) {
        this.$router.push("/");
      } else {
        this.decryptKey(this.$route.query.key as string);
      }
    }
  }

  protected async decryptKey(key: string) {
    try {
      const { firstName, lastName, userEmail, company, role, repEmail } = await this.authService.readEncryptedKey(
        key
      );
      this.first = unescape(firstName);
      this.last = unescape(lastName);
      this.email = unescape(userEmail);
      if (company) {
        this.optionalData.company = unescape(company);
      }
      if (role) {
        this.optionalData.role = role.toLowerCase() as Roles;
      }
      if (repEmail) {
        this.optionalData.repEmail = unescape(repEmail);
      }
    } catch (err) {
      if (err instanceof GenericError) {
        GenericError.popup(err.message);
      } else {
        this.error = err.message;
      }
    }
  }

  public async handleSubmit(): Promise<void> {
    try {
      await this.authService.register(
        this.first,
        this.last,
        this.email,
        this.password,
        this.optionalData
      );
      this.$router.push("/success?success=true");
    } catch (err) {
      if (err instanceof GenericError) {
        GenericError.popup(err.message);
      } else {
        this.error = err.message;
      }
    }
  }
}
